import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import FormsLayout from "components/Layouts/FormsLayout";
import LoadingScreen from "components/LoadingScreen";
import FOInstallationRequestCertPdf from "pages/forms/fo/installation/FOInstallationRequestCertPdf";
import FormsFOInstallationRequest from "pages/forms/fo/installation/FormsFOInstallationRequest";
import ListFormsFOInstallationRequests from "pages/forms/fo/installation/ListFormsFOInstallationRequests";
import FormsFOValuationRequest from "pages/forms/fo/valuation/FormsFOValuationRequest";
import ListFormsFOValuationRequests from "pages/forms/fo/valuation/ListFormsFOValuationRequests";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));

// forms
const FormsIndex = Loadable(
  lazy(() => import("./pages/forms/FormsIndex"))
);

// user profile
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));

// user management
const UserList = Loadable(
  lazy(() => import("./pages/userManagement/UserList"))
);
const UserGrid = Loadable(
  lazy(() => import("./pages/userManagement/UserGrid"))
);
const AddNewUser = Loadable(
  lazy(() => import("./pages/userManagement/AddNewUser"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "forms",
        element: (
          <AuthGuard>
            <FormsLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "",
            element: <FormsIndex />,
          },
          {
            path: "fo/installation/request/new",
            element: <FormsFOInstallationRequest />,
          },
          {
            path: "fo/installation/requests",
            element: <ListFormsFOInstallationRequests />,
          },
          {
            path: "fo/installation/request/details/pdf",
            element: <FOInstallationRequestCertPdf />,
          },
          {
            path: "fo/valuation/request/new",
            element: <FormsFOValuationRequest />,
          },
          {
            path: "fo/valuation/requests",
            element: <ListFormsFOValuationRequests />,
          },
        ]
      },
      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "user-grid",
        element: <UserGrid />,
      },
      {
        path: "add-user",
        element: <AddNewUser />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
