import axiosInstance from "utils/axios";
import { accessToken } from "utils/state";

export const FO = {
    async listPartners() {
        try {
            const response = await axiosInstance.get('v1/partners/?limit=1000', {
                headers: {
                    'Authorization': `Token ${accessToken()}`
                }
            });
            return response;
        } catch (error) {
            return {
                status: 401,
                error
            };
        }
    }

};
