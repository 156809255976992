import FlexBox from "components/FlexBox";
import { H6, Small, Tiny } from "components/Typography";
import moment from "moment";

const ColumnShape = [
  {
    Header: "Customer",
    accessor: "customer_names",
    minWidth: 200,
    Cell: ({ row }: any) => {
      const { customer_names, customer_phone_number } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column" ml={1}>
            <H6 color="text.primary">{customer_names}</H6>
            <Tiny color="text.disabled">{customer_phone_number}</Tiny>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Reg No",
    accessor: "reg_no",
    minWidth: 150,
    Cell: ({ value }: any) => (
      <Small
        sx={{
          borderRadius: 10,
          padding: ".2rem 1rem",
          color: "background.paper",
          backgroundColor: "#A798FF",
        }}
      >
        {value}
      </Small>
    ),
  },
  {
    Header: "Partner",
    accessor: "partner",
    minWidth: 150,
    Cell: ({ row }: any) => {
      const { partner, partner_branch } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column" ml={1}>
            <H6 color="text.primary">{partner?.names}</H6>
            <Tiny color="text.disabled">{partner_branch?.name}</Tiny>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Inv (KShs)",
    accessor: "invoice_amount",
    minWidth: 150,
  },
  {
    Header: "Comm (KShs)",
    accessor: "commission_amount",
    minWidth: 150,
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: "Date",
    accessor: "created_at",
    minWidth: 140,
    Cell: ({ value }: any) => (
      <>
        {(value !== undefined) ? moment(new Date(value).toLocaleString()).format("YYYY-MM-DD HH:mm:ss") : 'None'}
      </>
    ),
  },
];

export default ColumnShape;
