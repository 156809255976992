import {
  Box, styled,
  CircularProgress,
  Card
} from "@mui/material";

import FlexBox from "components/FlexBox";
import useTitle from "hooks/useTitle";
import React, { FC, useEffect, useState } from "react";
import Iframe from "react-iframe";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loggedInUser } from "utils/state";

// styled component
const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const FOInstallationRequestCertPdf: FC = () => {
  // change navbar title
  useTitle("Installation request Cert PDF");

  const user = loggedInUser();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  let cert_link = searchParams.get("cert_link");

  const [state, setState] = useState('loaded');

  useEffect(() => {
    if (state !== 'loaded') {
      setState('loading');
    }

  }, [state]);


  return (
    <Box pt={2} pb={4} sx={{}}>
      {(state === "loading") ?
        <CircularProgress sx={{ display: "flex", justifyContent: "space-evenly" }} />
        :
        <Card sx={{
          alignContent: 'center',
          justifyContent: 'space-around',
          minHeight: 600,
          padding: "0.5rem"
        }}>
          <Iframe

            url={`${cert_link}`}
            width="100%"
            height="600px"
          />
        </Card>
      }
    </Box>
  );
};

export default FOInstallationRequestCertPdf;
