import {
  Box, Button, styled, Dialog,
  List, ListItem, ListItemText,
  AppBar, Toolbar, Typography, Slide,
  IconButton,
  Divider,
  CircularProgress
} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';

import FlexBox from "components/FlexBox";
import ColumnShape from "components/Forms/InstallationRequest/ColumnShape";
import TableDesign from "components/Forms/InstallationRequest/TableDesign";
import useTitle from "hooks/useTitle";
import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { listSubmissions } from "redux/forms";
import { loggedInUser } from "utils/state";
import CloseIcon from "icons/CloseIcon";
import ObjectDialogView from "components/ObjectDialogView";
import LightIconButton from "components/LightIconButton";
import PlusIcon from "icons/PlusIcon";
import ShareIcon from "icons/ShareIcon";
import moment from "moment";
import { H4 } from "components/Typography";
import PdfIcon from "icons/PdfIcon";

// styled component
const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const ListFormsFOInstallationRequests: FC = () => {
  // change navbar title
  useTitle("Installation requests");

  const user = loggedInUser();

  const navigate = useNavigate();
  const handleAddInstallation = () => navigate("/dashboard/forms/fo/installation/request/new");

  const [state, setState] = useState('loading');
  const [open, setOpen] = useState(false);
  const [curViewRow, setCurViewRow] = useState(null);
  const [rows, setRows] = useState([]);

  const handleRowClicked = (row: any) => {
    setOpen(true);
    setCurViewRow(row);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setCurViewRow(null);
  };

  useEffect(() => {
    if (state !== 'loaded') {
      setState('loading');
      listSubmissions({
        params: {
          created_by: user?.id
        },
        url: `/v1/tracking/account-requests/`
      }).then((response: any) => {
        setState('loaded');
        if (response?.status === 200) {
          setRows(response.data.results);
        } else {
          toast.error('Something went wrong.');
        }
        setState('loaded');
      }).catch((error) => {
        toast.error(error);
        setState('loaded');
      });
    }

  }, [rows, state]);


  const DialogInfo: FC = () => {

    const Transition = React.forwardRef(function Transition(
      props: TransitionProps & {
        children: React.ReactElement;
      },
      ref: React.Ref<unknown>,
    ) {
      return <Slide direction="up" ref={ref} {...props} />;
    });


    const objectValue = (obj: any, _: string) => {
      return (obj !== null) ? (typeof (obj[_]) === "object") ? (['partner', 'partner_branch'].includes(_)) ? obj[_]['name'] : '' : (obj !== null) ? obj[_] : '' : '';
    };

    const objectToLines = (obj: any) => {
      var lines = ``;
      if (obj !== null) {
        lines += `*New installation request by ${obj?.created_by}*`;
        lines += `\n`;
        lines += `*Date: ${(obj?.created_at !== undefined) ? moment(new Date(obj?.created_at).toLocaleString()).format("YYYY-MM-DD HH:mm:ss") : 'None'}*`;
        lines += `\n\n`;
        lines += `*Customer*`;
        lines += `\n`;
        lines += `*${obj?.customer_names}*`;
        lines += `\n`;
        lines += `*${obj?.customer_phone_number}*`;
        lines += `\n\n`;
        lines += `*Vehicle*`;
        lines += `\n`;
        lines += `Make: ${obj?.motor_make}`;
        lines += `\n`;
        lines += `Reg no: ${obj?.reg_no}`;
        lines += `\n\n`;
        lines += `Bank: ${obj?.partner.names}, ${obj?.partner_branch.name}`;
        lines += `\n`;
        lines += `Invoice: ${obj?.invoice_amount}/=`;
        lines += `\n`;
        lines += `Comission: ${obj?.commission_amount}/=`;
        lines += `\n`;
        lines += `\n`;
        lines += `*Agent/Dealer*`;
        lines += `\n`;
        lines += `Name: ${obj?.agent_names}`;
        lines += `\n`;
        lines += `No: ${obj?.agent_mpesa_number}`;
        lines += `\n`;
        lines += `\n`;
        lines += `*Contact person*`;
        lines += `\n`;
        lines += `Name: ${obj?.contact_person_names}`;
        lines += `\n`;
        lines += `No: ${obj?.contact_person_phone_number}`;
        lines += `\n`;
        lines += `\n`;
        lines += `Place: ${obj?.place_of_installation}`;
        lines += `\n`;
        lines += `Duration: ${obj?.duration} Months`;
        lines += `\n`;
        lines += `Notes: ${obj?.notes}`;
        lines += `\n\n`;
        lines += `*Posted by ${user?.first_name} ${user?.last_name}*`;
      }
      return lines;
    };

    const handleShare = async () => {
      var message = objectToLines(curViewRow);
      if (navigator.share) {
        try {
          await navigator.share({
            title: `New installation request by ${user?.username}`,
            text: `${message}`,
          });
          toast.success('Shared successfully.');
        } catch (error) {
          toast.error(`Error sharing ${error}`);
        }
      } else {
        toast.error('Web Share API not supported');
        let url = `https://web.whatsapp.com/send?group=`;
        url += `&text=${encodeURI(message)}&app_absent=0`;
        window.open(url);
      }
    };

    return <Dialog
      open={open}
      onClose={handleCloseDrawer}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar sx={{ position: 'relative', backgroundColor: "background.secondary" }}>
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleShare}
            aria-label="close"
          >
            <ShareIcon />
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDrawer}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {(curViewRow !== null || curViewRow !== undefined) ?
        <List>

          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <React.Fragment>
                  <LightIconButton onClick={() => { navigate(`/dashboard/forms/fo/installation/request/details/pdf/?cert_link=${objectValue(curViewRow || null, "cert_pdf_link")}`); }}>
                    <PdfIcon />
                    PDF CERT
                  </LightIconButton>
                </React.Fragment>
              }
              secondary={""}
            />
          </ListItem>
          {Object.keys(curViewRow || []).map((_) => {
            return <ListItem alignItems="flex-start">
              <ListItemText
                primary={_.replaceAll("_", " ").toUpperCase()}
                secondary={
                  <React.Fragment>
                    <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                      {objectValue(curViewRow || null, _)}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          })}

        </List>
        : <></>}
    </Dialog>

  };

  return (
    <Box pt={2} pb={4} sx={{}}>
      <StyledFlexBox>
        <LightIconButton onClick={handleAddInstallation} size="small">
          <PlusIcon fontSize="small" />
          Create
        </LightIconButton>
      </StyledFlexBox>
      <Box sx={{}}>
        {(state === "loading") ?
          <CircularProgress sx={{ display: "flex", justifyContent: "space-evenly" }} />
          :
          <>
            {(rows.length > 0 && state == "loaded") ?
              <TableDesign columnShape={ColumnShape} data={rows} rowClick={((rowData: object) => handleRowClicked(rowData))} />
              :
              <H4>No data to see</H4>
            }
          </>
        }
      </Box>
      <DialogInfo />
    </Box>
  );
};

export default ListFormsFOInstallationRequests;
