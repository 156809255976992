import {
  alpha,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  styled,
  Switch,
  TextField,
  Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Divider, FormHelperText,
} from "@mui/material";
import useAuth from "hooks/useAuth";
import useTitle from "hooks/useTitle";
import { FC, SyntheticEvent, useContext, useState } from "react";
import * as Yup from "yup";
import LightTextField from "components/LightTextField";
import { H4, H6, Small, Tiny } from "components/Typography";
import { useFormik } from "formik";
import { LangContext } from "contexts/LangContext";
import LightButton from "components/LightButton";
import { saveForm } from "redux/forms";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

const FormsFOValuationRequest: FC = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");


  // change navbar title
  useTitle(`New valuation request`);

  const initialValues = {
    customer_phone_number: "",
    customer_names: "",

    reg_no: "",
    motor_make: "",

    place_of_installation: "",

    partner: "",
    partner_branch: "",

    duration: '',

    invoice_amount: '',
    commission_amount: '',

    contact_person_names: '',
    contact_person_phone_number: ''

  };

  const validationSchema = Yup.object().shape({
    // customer_phone_number: Yup.number().required(`Customer phone number is required!`),
    // customer_names: Yup.string().required(`Customer names is required!`),

    // reg_no: Yup.string().required(`Reg no is required!`),
    // motor_make: Yup.string().required(`Motor make is required!`),

    // place_of_installation: Yup.string().required(`Place of installation is required!`),

    // // partner: Yup.string().required(`Partner is required!`),
    // // partner_branch: Yup.string().required(`Partner branch is required!`),

    // duration: Yup.number().required(`Duration is required!`),

    // invoice_amount: Yup.number().required(`Invoice amount is required!`),
    // commission_amount: Yup.number().required(`Commission is required!`),

    // contact_person_names: Yup.string().required(`Contact person name is required!`),
    // contact_person_phone_number: Yup.number().required(`Contact person phone number is required!`),

  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      var form: any = {
        url: 'v1/tracking/account-requests/',
        payload: values
      };
      setLoading(true);
      saveForm(form).then((response) => {
        toast.success("Form saved");
        setLoading(false);
      }).catch((error) => {
        setError(error.message);
        setLoading(false);
      });
    },
  });

  return (
    <Box pt={1} pb={1}>
      <Card sx={{ padding: 1 }}>
        <Grid container spacing={1}>
          <Grid item md={8} xs={12} lg={8}>
            <Card sx={{ padding: 2, boxShadow: 2 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="agent"
                      label="Loggedin as"
                      value={user?.email}
                      disabled={true}
                    />
                  </Grid>

                  <Divider sx={{ my: 1 }} />

                  <Grid item sm={12} xs={12}>
                    <Small> Customer </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="customer_names"
                      label="Customer names"
                      value={values.customer_names}
                      onChange={handleChange}
                      error={Boolean(touched.customer_names && errors.customer_names)}
                      helperText={touched.customer_names && errors.customer_names}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="customer_phone_number"
                      label="Customer phone number"
                      value={values.customer_phone_number}
                      onChange={handleChange}
                      error={Boolean(touched.customer_phone_number && errors.customer_phone_number)}
                      helperText={touched.customer_phone_number && errors.customer_phone_number}
                    />
                  </Grid>

                  <Divider sx={{ my: 1 }} />

                  <Grid item sm={12} xs={12}>
                    <Small> Vehicle </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="reg_no"
                      label="Reg No"
                      value={values.reg_no}
                      onChange={handleChange}
                      error={Boolean(touched.reg_no && errors.reg_no)}
                      helperText={touched.reg_no && errors.reg_no}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="motor_make"
                      label="Motor make"
                      value={values.motor_make}
                      onChange={handleChange}
                      error={Boolean(touched.motor_make && errors.motor_make)}
                      helperText={touched.motor_make && errors.motor_make}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="place_of_installation"
                      label="Place of installation"
                      value={values.place_of_installation}
                      onChange={handleChange}
                      error={Boolean(touched.place_of_installation && errors.place_of_installation)}
                      helperText={touched.place_of_installation && errors.place_of_installation}
                    />
                  </Grid>

                  <Divider sx={{ my: 1 }} />

                  <Grid item sm={12} xs={12}>
                    <Small> Bank / Partner </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="partner"
                      select
                      label="Bank / Partner"
                      value={values.partner}
                      onChange={handleChange}
                      error={Boolean(touched.partner && errors.partner)}
                      helperText={touched.partner && errors.partner}
                    >
                      {([]).map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="partner_branch"
                      select
                      label="Bank branch"
                      value={values.partner_branch}
                      onChange={handleChange}
                      error={Boolean(touched.partner_branch && errors.partner_branch)}
                      helperText={touched.partner_branch && errors.partner_branch}
                    >
                      {([]).map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Divider sx={{ my: 1 }} />


                  <Grid item sm={12} xs={12}>
                    <Small> Duration and Amounts </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="number"
                      size="small"
                      name="duration"
                      label="Tracking duration"
                      value={values.duration}
                      onChange={handleChange}
                      error={Boolean(touched.duration && errors.duration)}
                      helperText={touched.duration && errors.duration}
                    />
                  </Grid>


                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="number"
                      size="small"
                      name="invoice_amount"
                      label="Invoice amount (KES)"
                      value={values.invoice_amount}
                      onChange={handleChange}
                      error={Boolean(touched.invoice_amount && errors.invoice_amount)}
                      helperText={touched.invoice_amount && errors.invoice_amount}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="number"
                      size="small"
                      name="commission_amount"
                      label="Commission amount (KES)"
                      value={values.commission_amount}
                      onChange={handleChange}
                      error={Boolean(touched.commission_amount && errors.commission_amount)}
                      helperText={touched.commission_amount && errors.commission_amount}
                    />
                  </Grid>

                  <Divider sx={{ my: 1 }} />

                  <Grid item sm={12} xs={12}>
                    <Small> Contact person </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="contact_person_names"
                      label="Names"
                      value={values.contact_person_names}
                      onChange={handleChange}
                      error={Boolean(touched.contact_person_names && errors.contact_person_names)}
                      helperText={touched.contact_person_names && errors.contact_person_names}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="contact_person_phone_number"
                      label="Phone"
                      value={values.contact_person_phone_number}
                      onChange={handleChange}
                      error={Boolean(touched.contact_person_phone_number && errors.contact_person_phone_number)}
                      helperText={touched.contact_person_phone_number && errors.contact_person_phone_number}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {error && (
                      <FormHelperText
                        error
                        sx={{
                          mt: 2,
                          fontSize: 13,
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </FormHelperText>
                    )}

                    {loading ? (
                      <LoadingButton loading fullWidth variant="contained">
                        Submit
                      </LoadingButton>
                    ) : (
                      <LightButton fullWidth type="submit" variant="contained">
                        Submit
                      </LightButton>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default FormsFOValuationRequest;
