import Icons from "icons/sidebar";

const index = [
  {
    title: "Dashboard",
    Icon: Icons.DashboardIcon,
    path: "/dashboard",
  },
  {
    title: "Forms",
    Icon: Icons.FormsIcon,
    path: "/dashboard/forms",
  }
];

export default index;
