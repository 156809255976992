import {
  Box,
  Card,
  Divider,
  Grid,
  MenuItem,
  Switch,
  styled,
} from "@mui/material";
import useTitle from "hooks/useTitle";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import LightTextField from "components/LightTextField";
import { Small } from "components/Typography";
import { useFormik } from "formik";
import LightButton from "components/LightButton";
import { saveForm } from "redux/forms";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { loggedInUser } from "utils/state";
import { FO } from "redux/initialize/forms";
import { useNavigate } from "react-router-dom";

const FormsFOInstallationRequest: FC = () => {
  // change navbar title
  useTitle(`New installation request`);

  const navigate = useNavigate();

  const user = loggedInUser();

  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [partner_branches, setPartnerBranches] = useState([]);
  const [client_is_cp, setClientisCP] = useState(false);

  const initialValues = {
    customer_phone_number: "",
    customer_names: "",

    reg_no: "",
    motor_make: "",

    place_of_installation: "",

    partner: "",
    partner_branch: "",

    duration: '',

    invoice_amount: '',
    commission_amount: '',

    contact_person_names: '',
    contact_person_phone_number: '',

    agent_names: '',
    agent_mpesa_number: ''
  };

  const validationSchema = Yup.object().shape({
    customer_phone_number: Yup.number().required(`Customer phone number is required!`),
    customer_names: Yup.string().required(`Customer names is required!`),

    reg_no: Yup.string().required(`Reg no is required!`),
    motor_make: Yup.string().required(`Motor make is required!`),

    place_of_installation: Yup.string().required(`Place of installation is required!`),

    partner: Yup.number().required(`Partner is required!`),
    partner_branch: Yup.number().required(`Partner branch is required!`),

    duration: Yup.number().required(`Duration is required!`),

    invoice_amount: Yup.number().required(`Invoice amount is required!`),
    commission_amount: Yup.number().required(`Commission is required!`),

    agent_mpesa_number: Yup.number().required(`Agent phone number is required!`),
    agent_names: Yup.string().required(`Agent names is required!`),

    // contact_person_names: Yup.string().required(`Contact person name is required!`),
    // contact_person_phone_number: Yup.number().required(`Contact person phone number is required!`),

  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      var payload = {
        ...values
      };
      console.log(payload);
      var form: any = {
        url: 'v1/tracking/account-requests/',
        payload
      };
      setLoading(true);
      saveForm(form).then((response) => {
        if (response?.status === 201) {
          toast.success("Form saved");
          navigate('/dashboard/forms/fo/installation/requests')
        } else {
          toast.error("Something went wrong");
        }
        setLoading(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
    },
  });


  useEffect(() => {
    if (partners.length <= 0) {
      FO.listPartners().then((response: any) => {
        if (response?.status === 200) {
          setPartners(response?.data.results);
        }
      }).catch((error) => {

      });
    }

    if (partners.length > 0 && values.partner !== '') {
      var branches = partners.filter((_) => _ !== undefined && _['id'] === values.partner)[0]['branches'];
      setPartnerBranches(branches);
    }

    // if (client_is_cp) {
    //   values.contact_person_names = values.customer_names;
    //   values.contact_person_phone_number = values.customer_phone_number;
    // }
  }, [partners, values, client_is_cp]);



  const SwitchWrapper = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 10,
  }));

  const handleCheck = (e: any) => {
    e.preventDefault();
    if (e.target.checked) {
      values.contact_person_names = values.customer_names;
      values.contact_person_phone_number = values.customer_phone_number;
    } else {
      values.contact_person_names = '';
      values.contact_person_phone_number = '';
    }
    setClientisCP(e.target.checked);
  };

  return (
    <Box pt={1} pb={1}>
      <Card sx={{ padding: 1 }}>
        <Grid container spacing={1}>
          <Grid item md={8} xs={12} lg={8}>
            <Card sx={{ padding: 2, boxShadow: 2 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="agent"
                      label="Loggedin as"
                      value={user?.email}
                      disabled={true}
                    />
                  </Grid>

                  <Divider sx={{ my: 1 }} />

                  <Grid item sm={12} xs={12}>
                    <Small> Customer </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="customer_names"
                      label="Customer names"
                      value={values.customer_names}
                      onChange={handleChange}
                      error={Boolean(touched.customer_names && errors.customer_names)}
                      helperText={touched.customer_names && errors.customer_names}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="customer_phone_number"
                      label="Customer phone number"
                      value={values.customer_phone_number}
                      onChange={handleChange}
                      error={Boolean(touched.customer_phone_number && errors.customer_phone_number)}
                      helperText={touched.customer_phone_number && errors.customer_phone_number}
                    />
                  </Grid>

                  <Divider sx={{ my: 1 }} />

                  <Grid item sm={12} xs={12}>
                    <Small> Vehicle </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="reg_no"
                      label="Reg No"
                      value={values.reg_no}
                      onChange={handleChange}
                      error={Boolean(touched.reg_no && errors.reg_no)}
                      helperText={touched.reg_no && errors.reg_no}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="motor_make"
                      label="Motor make"
                      value={values.motor_make}
                      onChange={handleChange}
                      error={Boolean(touched.motor_make && errors.motor_make)}
                      helperText={touched.motor_make && errors.motor_make}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="place_of_installation"
                      label="Place of installation"
                      value={values.place_of_installation}
                      onChange={handleChange}
                      error={Boolean(touched.place_of_installation && errors.place_of_installation)}
                      helperText={touched.place_of_installation && errors.place_of_installation}
                    />
                  </Grid>

                  <Divider sx={{ my: 1 }} />

                  <Grid item sm={12} xs={12}>
                    <Small> Bank / Partner </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="partner"
                      select
                      label="Bank / Partner"
                      value={values.partner}
                      onChange={handleChange}
                      error={Boolean(touched.partner && errors.partner)}
                      helperText={touched.partner && errors.partner}
                    >
                      {(partners).map((option: any, index) => (
                        <MenuItem key={index} value={option.id}>
                          {option.names}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="partner_branch"
                      select
                      label="Bank branch"
                      value={values.partner_branch}
                      onChange={handleChange}
                      error={Boolean(touched.partner_branch && errors.partner_branch)}
                      helperText={touched.partner_branch && errors.partner_branch}
                    >
                      {(partner_branches).map((option: any, index) => (
                        <MenuItem key={index} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Divider sx={{ my: 1 }} />


                  <Grid item sm={12} xs={12}>
                    <Small> Duration and Amounts </Small>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="number"
                      size="small"
                      name="duration"
                      label="Tracking duration (Months)"
                      value={values.duration}
                      onChange={handleChange}
                      error={Boolean(touched.duration && errors.duration)}
                      helperText={touched.duration && errors.duration}
                    />
                  </Grid>


                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="number"
                      size="small"
                      name="invoice_amount"
                      label="Invoice amount (KES)"
                      value={values.invoice_amount}
                      onChange={handleChange}
                      error={Boolean(touched.invoice_amount && errors.invoice_amount)}
                      helperText={touched.invoice_amount && errors.invoice_amount}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="number"
                      size="small"
                      name="commission_amount"
                      label="Commission amount (KES)"
                      value={values.commission_amount}
                      onChange={handleChange}
                      error={Boolean(touched.commission_amount && errors.commission_amount)}
                      helperText={touched.commission_amount && errors.commission_amount}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Small> Agent recieving commission </Small>
                  </Grid>
                  <Grid item sm={12} xs={12}>

                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="agent_names"
                      label="Names"
                      value={values.agent_names}
                      onChange={handleChange}
                      error={Boolean(touched.agent_names && errors.agent_names)}
                      helperText={touched.agent_names && errors.agent_names}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="agent_mpesa_number"
                      label="Mpesa No"
                      value={values.agent_mpesa_number}
                      onChange={handleChange}
                      error={Boolean(touched.agent_mpesa_number && errors.agent_mpesa_number)}
                      helperText={touched.agent_mpesa_number && errors.agent_mpesa_number}
                    />
                  </Grid>


                  <Divider sx={{ my: 1 }} />

                  <Grid item sm={12} xs={12}>
                    <Small> Contact person </Small>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <SwitchWrapper>
                      <Small display="block" >
                        Client is contact person
                      </Small>
                      <Switch onChange={handleCheck} checked={client_is_cp} />
                    </SwitchWrapper>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="contact_person_names"
                      label="Names"
                      value={values.contact_person_names}
                      onChange={handleChange}
                      disabled={client_is_cp}
                      error={Boolean(touched.contact_person_names && errors.contact_person_names)}
                      helperText={touched.contact_person_names && errors.contact_person_names}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="contact_person_phone_number"
                      label="Phone"
                      disabled={client_is_cp}
                      value={values.contact_person_phone_number}
                      onChange={handleChange}
                      error={Boolean(touched.contact_person_phone_number && errors.contact_person_phone_number)}
                      helperText={touched.contact_person_phone_number && errors.contact_person_phone_number}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {/* {error && (
                      <FormHelperText
                        error
                        sx={{
                          mt: 2,
                          fontSize: 13,
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </FormHelperText>
                    )} */}

                    {loading ? (
                      <LoadingButton loading fullWidth variant="contained">
                        Submit
                      </LoadingButton>
                    ) : (
                      <LightButton fullWidth type="submit" variant="contained">
                        Submit
                      </LightButton>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box >
  );
};
export default FormsFOInstallationRequest;
