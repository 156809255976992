const loggedInUser = () => {
    var user: any = localStorage.getItem("user");
    return JSON.parse(user);
};

const accessToken = () => {
    var token: any = localStorage.getItem("accessToken");
    return token;
};

export { loggedInUser, accessToken };
